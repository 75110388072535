import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.c-slider1', {
	loop: true,
	speed: 600,
	centeredSlides: true,
	slidesPerView: 'auto',
	threshold: 10,
	navigation: {
		nextEl: '.c-slider1 .swiper-button-next',
		prevEl: '.c-slider1 .swiper-button-prev',
	},
	pagination: {
		el: '.c-slider1 .swiper-pagination',
		type: 'fraction',
	},
});

// スライダー
const swiperThumbnail = new Swiper('.c-slider2-thumbnail', {
	slidesPerView: 6,
	spaceBetween: 10,
});

// スライダー
const swiper2 = new Swiper('.c-slider2', {
	loop: true,
	speed: 600,
	threshold: 10,
	thumbs: {
		swiper: swiperThumbnail,
	},
	pagination: {
		el: '.c-slider2 .swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.c-slider2 .swiper-button-next',
		prevEl: '.c-slider2 .swiper-button-prev',
	},
});

// スライダー
const swiper3 = new Swiper('.p-top3-slider3__wrap', {
	loop: true,
	speed: 500,
	threshold: 10,
	slidesPerView: 1.28,

	autoplay: {
		delay: 3000,
		disableOnInteraction: false, // スライドに触っても自動再生を停止しない
	},

	breakpoints: {
		768: {
			centeredSlides: true,
			slidesPerView: 2.6,
		},
		940: {
			centeredSlides: true,
			slidesPerView: 2.8,
		},
		1100: {
			centeredSlides: true,
			slidesPerView: 3.1,
		},
	},
});
