import $ from 'jquery';
import Anchor from '../Components/_Anchor.js';
import Swiper from '../Components/_Slider.js';
import { gsap } from 'gsap';

// サイドメニュー開閉処理
$('.c-side0__choose__btn').on('click', function () {
	$(this).toggleClass('is-open');
	$(this).next().slideToggle();
});

$(document).ready(function () {
	// 初期表示時にも処理を行う
	$('.js-fade').each(function () {
		var pos = $(this).offset().top;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > pos - windowHeight + 100) {
			$(this).addClass('scroll');
		}
	});
});

$(window).on('scroll', function () {
	$('.js-fade').each(function () {
		var pos = $(this).offset().top;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > pos - windowHeight + 100) {
			$(this).addClass('scroll');
		}
	});
});

$(function () {
	// ハンバーガーメニュー
	$('.js-header-open').on('click', function () {
		let target = $(this).data('target');
		$(this).toggleClass('is-open');
		$('.c-header1__nav[data-header="' + target + '"]').toggleClass('is-open');

		if ($('.c-header1__nav').hasClass('is-open')) {
			$('.c-header1__logo img').attr(
				'src',
				'/wp-content/themes/wp/assets/images/common/logo-white.svg',
			);
		} else {
			$('.c-header1__logo img').attr(
				'src',
				'/wp-content/themes/wp/assets/images/common/logo.svg',
			);
		}
	});

	$('.c-header1__link').on('click', function () {
		let target = $(this).closest('.c-header1__nav').data('header');
		$('.c-header1__nav[data-header="' + target + '"]').removeClass('is-open');
	});
});

window.addEventListener('load', function () {
	// ページがリロードされたかどうかを確認
	// ページリロード時
	if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
		const sections = document.querySelectorAll('.section');
		const logoContainer = document.querySelector('.logo-container');

		// ロゴのフェードイン (下からのアニメーション)
		gsap.to(logoContainer, {
			duration: 1,
			opacity: 1,
			y: -20, // Y軸の位置を少し上にアニメーションさせる
			ease: 'power2.out',
			delay: 0.5, // ロゴのフェードインを少し遅らせる
		});

		setTimeout(() => {
			const timeline = gsap.timeline({
				onComplete: function () {
					document.querySelector('.loading-screen').style.display = 'none';
				},
			});

			timeline.to(sections, {
				duration: 0.8,
				height: 0,
				stagger: 0.1,
				ease: 'power2.inOut',
			});

			timeline.to(
				logoContainer,
				{
					duration: 0.3,
					opacity: 0,
				},
				'-=0.6',
			);
		}, 1500);
	} else {
		// ページ遷移時
		const sections = document.querySelectorAll('.section');

		setTimeout(() => {
			const timeline = gsap.timeline({
				onComplete: function () {
					document.querySelector('.loading-screen').style.display = 'none';
				},
			});

			timeline.to(sections, {
				duration: 0.8,
				height: 0,
				stagger: 0.1,
				ease: 'power2.inOut',
			});
		});
	}
});

// フローティングバナー
// クローズ状態を記録するフラグ
let isBannerClosed = false;

$(window).on('scroll', function () {
	// バナーがクローズされていない場合のみ処理
	if (!isBannerClosed) {
		if ($(this).scrollTop() > 100) {
			$('.c-floatingBanner').fadeIn(300);
		} else {
			$('.c-floatingBanner').fadeOut(300);
		}
	}
});

// クローズボタンのクリックイベント
$('.c-floatingBanner__close').on('click', function () {
	$('.c-floatingBanner').fadeOut(300);
	// クローズ状態を記録
	isBannerClosed = true;
});
